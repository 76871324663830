export const STATUS_NAMES = {
    "offline": "Không hoạt động",
    "online": "Đang hoạt động",
    "away": "Đi vắng",
    "busy": "Đang bận"
}
export const INTERNAL_REQUEST_DEFAULT = {
    type: null,
    basket_code: null,
    cell_id: null,
    money: null,
    note: null,
    order_tracking_id: null,
    quantity: null,
    receipt_tracking_id: null,
    sku: null,
    working_shift: null,
    working_date: null,
}

export const INTERNAL_PICKUP_REDUCE = 3
export const INTERNAL_RETURN_HAS_MONEY = 4
export const INTERNAL_REQUEST_BONUS = 16
export const INTERNAL_REQUEST_PUNISHMENT = 17
export const ALLOW_REQUEST_CREATE_TYPES = [
    10, 11, 12, 16, 17, 19, 21,
    91, 99
]

export const RETURN_RECEIVE_REASONS = [
    {
        value: 'Mất / Thiếu hàng',
        text: 'Mất / Thiếu hàng'
    },
    {
        value: 'P.Bì bị rạch mở, lộ mã kích hoạt',
        text: 'P.Bì bị rạch mở, lộ mã kích hoạt'
    },
    {
        value: 'Hàng bị ướt',
        text: 'Hàng bị ướt'
    },
    {
        value: 'Hàng bị vỡ / hỏng',
        text: 'Hàng bị vỡ / hỏng'
    },
    {
        value: 'Hàng bị tráo / đổi',
        text: 'Hàng bị tráo / đổi'
    },
    {
        value: 'VC. Trả nhầm đơn',
        text: 'VC. Trả nhầm đơn'
    },
    {
        value: 'Thừa hàng',
        text: 'Thừa hàng'
    },
    {
        value: 'Khác',
        text: 'Khác'
    },
]

export const RETURN_IMPORT_REASONS = [
    {
        value: 'HỦY HÀNG - Đối tác yêu cầu',
        text: 'HỦY HÀNG - Đối tác yêu cầu'
    },
    {
        value: 'TRẢ HÀNG - Đối tác yêu cầu',
        text: 'TRẢ HÀNG - Đối tác yêu cầu'
    },
    {
        value: 'TRẢ HÀNG - Hình thức không đạt',
        text: 'TRẢ HÀNG - Hình thức không đạt'
    },
    {
        value: 'TRẢ HÀNG - Bao bì hư hại',
        text: 'TRẢ HÀNG - Bao bì hư hại'
    },
    {
        value: 'TRẢ HÀNG - Hàng hóa hư hại',
        text: 'TRẢ HÀNG - Hàng hóa hư hại'
    },
    {
        value: 'Không có hàng',
        text: 'Không có hàng'
    },
    {
        value: 'Giao một phần',
        text: 'Giao một phần'
    },
    {
        value: 'Hết hạn sử dụng',
        text: 'Hết hạn sử dụng'
    },
    {
        value: 'Khác',
        text: 'Khác'
    },
]

export const GOODS_ISSUE_STATE = [
    {
        text: 'Hủy đơn',
        value: 0
    },
    {
        text: 'Thiếu hàng',
        value: 20
    },
    {
        text: 'Chờ mã vận chuyển',
        value: 35
    },
    {
        text: 'Đã tiếp nhận',
        value: 40
    },
    {
        text: 'Đang xử lý',
        value: 60
    },
    {
        text: 'Đã pick',
        value: 100
    },
    {
        text: 'Đã đóng gói',
        value: 160
    },
    {
        text: 'Đã bàn giao vận chuyển',
        value: 200
    },
    {
        text: 'Đã nhận hoàn',
        value: 220
    },
    {
        text: 'Đã nhập hoàn',
        value: 240
    },
]

export const PICKUP_COMMAND_STATUS = [
    {
        text: 'Chưa Pick',
        value: 0
    },
    {
        text: 'Đã Pick',
        value: 1
    },
]

export const PICKUP_COMMAND_PROCESS_TYPE = [
    {
        text: '1',
        value: 1
    },
    {
        text: '2',
        value: 2
    },
    {
        text: '3',
        value: 3
    },
]

export const PICKUP_COMMAND_TYPE = [
    {
        text: '01 sp',
        value: 0
    },
    {
        text: '≤32 cm || ≥ 02 sp || 01 sku',
        value: 1
    },
    {
        text: '≤32 cm || ≥ 02 sp || 02 sku',
        value: 2
    },
    {
        text: '≤32 cm || ≤ 20 sp || 03 sku (↑)',
        value: 3
    },
    {
        text: '>32 cm || ≥ 02 sp || 01 sku',
        value: 4
    },
    {
        text: '>32 cm || ≥ 02 sp || 02 sku',
        value: 5
    },
    {
        text: '>32 cm || ≤ 20 sp || 03 sku (↑)',
        value: 6
    },
    {
        text: '> 20 sp',
        value: 100
    },
    {
        text: 'Tracking ID',
        value: 101
    },
]

export const DELIVERY_STATE = [
    {
        text: 'Chờ tiếp nhận',
        value: 50
    },
    {
        text: 'Đang nhận',
        value: 55
    },
    {
        text: 'Đã lấy hàng',
        value: 100
    },
    {
        text: 'Đang giao',
        value: 200
    },
    {
        text: 'Chưa giao được',
        value: 225
    },
    {
        text: 'Đã giao hàng - Chờ đối soát',
        value: 250
    },
    {
        text: 'Không giao được - Chờ hoàn',
        value: 275
    },
    {
        text: 'Thành công',
        value: 400
    },
    {
        text: 'Thành công có hoàn',
        value: 401
    },
    {
        text: 'Đơn hoàn',
        value: 402
    },
    {
        text: 'Hủy đơn',
        value: 700
    },
    {
        text: 'Bồi thường',
        value: 800
    },
    {
        text: 'Tự vận chuyển',
        value: 999
    },
]

export const STAMP_STATUS = [
    {
        text: 'Không cần',
        value: 1
    },
    {
        text: 'Chưa có',
        value: 2
    },
    {
        text: 'Đã có',
        value: 3
    },
]

export const IMAGE_OPTIONS = [
    {
        text: 'Có ',
        value: 'y'
    },
    {
        text: 'Không',
        value: 'n'
    },
]

export const TRANSPORT_OPTIONS = [
    {
        text: 'Road',
        value: 'road'
    },
    {
        text: 'Fly',
        value: 'fly'
    },
]

export const YES_NO_OPTIONS = [
    {
        text: 'Yes',
        value: 1
    },
    {
        text: 'No',
        value: 0
    },
]

export const STATUS_OPTIONS = [
    {
        text: 'Active',
        value: 1,
        color: 'success--text',
    },
    {
        text: 'Inactive',
        value: 0,
        color: 'error--text',
    },
]

export const GOODS_RETURN_SEAL_STATUS = [
    {
        text: 'Còn niêm phong',
        value: 1
    },
    {
        text: 'Mất niêm phong',
        value: 0
    },
]

export const WORKING_TIMES = [
    {
        text: 'Sáng',
        value: 1
    },
    {
        text: 'Chiều',
        value: 2
    },
    {
        text: 'Tối',
        value: 3
    },
    {
        text: 'Ngoài giờ',
        value: 0
    },
]

export const SOURCES = [
    {
        text: 'SSC',
        value: -2
    },
    {
        text: 'TVC',
        value: -1
    },
    {
        text: 'Shopee',
        value: 1
    },
    {
        text: 'Lazada',
        value: 2
    },
    {
        text: 'Tiktok',
        value: 5
    },
]

export const E_MARKET_SHOPEE = 1
export const E_MARKET_LAZADA = 2
export const E_MARKET_TIKTOK = 5

export const BARCODE_MAT_NIEM_PHONG = 'SSC-MNP-SSC'
export const BARCODE_REMOVE = 'SSC-RMRT-SSC'
export const BARCODE_HU_HAI = 'SSC-HUHAI-SSC'
export const BARCODE_REMOVE_HANDOVER = 'SSC-RMHO-SSC'
export const BARCODE_REMOVE_RECEIPT = 'SSC-RMRC-SSC'
export const BARCODE_FINISH_PACKING_1SKU = 'SSC-F1SKU-SSC'
export const BARCODE_PICKUP_LATER = '88SSC98'

export const POS_TABLE_PACKING = 12

export const ACTIVITIES_LOGS = {
    ACTION: {
        RECEIPT: 1, // nhap kho
        RECEIPT_STOWING: 2, // len ke nhap kho
        PICKUP: 3, // pickup
        PACKING: 4, // dong goi
        HANDOVER: 5, // ban giao van chuyen
        RETURN_RECEIVE: 6, // nhan hoan
        RETURN_IMPORT: 7, // nhap hoan
        RETURN_STOWING: 8, // len ke hang hoan
        RECOUP: 9, // boi thuong,
    },
    JOB: {
        ITEM: 1, // item
        ORDER: 2, // order
        FAIL_EMPLOYEE: 3, // fail by employee
        FAIL_SSC: 4, // fail by ssc,
        LOG: 99,
    },
    WORKING_SHIFT: {
        SA: 1, // ca sang
        CH: 2 // ca chieu
    },
}

export const CUSTOMER_PERMISSIONS = [
    'goods_receipt_create', // ky gui hang hoa
    'goods_receipt_history', // lich su nhap kho
    'goods_return_history', // lich su hang hoan
    'order_create_single', // tao don le
    'order_create_excel', // tao don excel
    'order_create_e_market', // tao don san
    'order_create_select_discount', // tao don chon km
    'goods_manager', // qly hang hoa
    'goods_category_price', // qly gia hang hoa
    'goods_reserved', // giu ton
    'order_manager', // qly don hang
    'order_cancel_and_change', // huy - doi don hang
    'request_manger', // yeu cau xu ly
]

export const CUSTOMER_POS_PERMISSIONS = [
    'pos_timekeeping',
    'pos_goods_transfer', // chuyen hang noi bo
    'pos_goods_manager', // qly hang hoa
    'pos_goods_uid_manager', // qly uid
    'pos_goods_receipt', // nhap pos
    'pos_goods_set_position', // len ke
    'pos_goods_pickup', // pickup
    'pos_goods_set_action', // cbi hang
    'pos_accrue_point_config', // qly tich diem
    'pos_voucher_config', // qly voucher
    'pos_promotion_config', // qly km
    'pos_order_create', // ban hang
    'pos_order_list', // don hang
    'pos_goods_display_request', // trung bay
    'pos_order_online_list', // don online -list
    'pos_order_online_pickup', // don online -pickup
    'pos_order_online_packing', // don online - dong goi
    'pos_order_online_handover', // don online - ban giao
    'pos_order_online_return_receive', // don online - nhan hoan
    'pos_order_online_return_import', // don online - nhap hoan
]

export const NUMBER_TO_TEXT = {
    0: "ZPK",
    1: "YNJ",
    2: "XMH",
    3: "WKG",
    4: "VJF",
    5: "UHE",
    6: "TGD",
    7: "SFC",
    8: "REB",
    9: "QDA"
};

export const NOTIFY_TYPE_OPTIONS = [12, 13, 15, 16, 17, 98]
export const NOTIFY_STATUS_OPTIONS = [0, 1]
export const SUPPLIER_TYPE_OPTIONS = [0, 1, 2]
export const PROFILE_TYPE_OPTIONS = [1, 2, 3]

export const ID_DELIVERY_GHN = 3
export const ID_DELIVERY_GHTK = 4
export const ID_DELIVERY_VNP = 33
export const ID_DELIVERY_JNT = 36
export const ID_DELIVERY_SPX = 74
export const ALLOW_FIELD_DELIVERY_ACCOUNT_GHTK = ['token']
export const ALLOW_FIELD_DELIVERY_ACCOUNT_GHN = ['token', 'shop_id']
export const ALLOW_FIELD_DELIVERY_ACCOUNT_SPX = ['app_id', 'app_secret', 'user_id', 'user_secret']
export const ALLOW_FIELD_DELIVERY_ACCOUNT_JNT = ['company_id', 'customer_id', 'token']
export const ALLOW_FIELD_DELIVERY_ACCOUNT_VNP = ['username', 'password']

export const DEFAULT_SSC_DELIVERY_CONTRACTS = [-1, 7]

export const SSC_DELIVERY_ID = -1
export const TVC_DELIVERY_ID = -2
export const DEFAULT_CONTRACT_IDS = [SSC_DELIVERY_ID, TVC_DELIVERY_ID]

export const NOTIFY_TYPE_COLOR = {
    1: 'purple lighten-3',
    2: 'error',
    3: 'warning',
    11: 'yellow lighten-2',
    12: 'amber lighten-2',
    13: 'orange lighten-2',
    14: 'deep-orange lighten-2',
    15: 'brown lighten-2',
    16: 'blue-grey lighten-2',
    17: 'warning',
    98: 'info',
    99: 'teal lighten-3',
}

export const RECEIPT_DETAIL_STATUS_OPTIONS = [
    {
        text: 'Chưa kiểm',
        value: 'ny'
    },
    {
        text: 'Đang kiểm',
        value: 'd'
    },
    {
        text: 'Đã kiểm',
        value: 'y'
    },
]

export const POS_ORDER_TYPES = [
    {
        text: 'Bán hàng',
        value: 1
    },
    {
        text: 'Đổi trả',
        value: 2
    },
]

export const BUYER_HIDDEN_INFO_CODE = 'SSC-HIDDENINF-SSC'
export const REMOVE_ITEM_CODE = 'SSC-POSREMOVE-SSC'
export const GENDER_MALE_CODE = 'SSC-MALE-SSC'
export const GENDER_FEMALE_CODE = 'SSC-FEMALE-SSC'

export const POS_ORDER_STEP = {
    PHONE: 1,
    // GENDER: 2,
    NAME: 2,
    BIRTH_DAY: 3,
    BIRTH_MONTH: 4,
    BIRTH_YEAR: 5,
    // UID_PACKAGE: 6,
}

export const POS_ORDER_BUYER_STEP_KEYS = {
    1: 'phone',
    // 2: 'gender',
    2: 'name',
    3: 'birthday_day',
    4: 'birthday_month',
    5: 'birthday_year',
}

export const GENDER_OPTIONS = [
    {
        text: 'Nam',
        value: 1,
    },
    {
        text: 'Nữ',
        value: 2,
    },
]

export const REQUEST_TYPE_OPTIONS = [
    {
        text: 'Cập nhật trạng thái đơn',
        value: 101,
        department: 1,
    },
    {
        text: 'Kiểm tra đóng gói',
        value: 201,
        department: 2,
    },
    {
        text: 'Kiểm tra nhập hoàn',
        value: 202,
        department: 3,
    },
    {
        text: 'Giục giao hàng',
        value: 301,
        department: 3,
    },
    {
        text: 'Sửa tiền thu hộ',
        value: 302,
        department: 3,
    },
    {
        text: 'Huỷ giao hàng',
        value: 303,
        department: 3,
    },
    {
        text: 'Hẹn giao hàng',
        value: 304,
        department: 3,
    },
    {
        text: 'Sửa thông tin liên hệ',
        value: 305,
        department: 3,
    },
    {
        text: 'Kiểm tra đối soát',
        value: 401,
        department: 4,
    },
    {
        text: 'Khác',
        value: 0,
        department: null,
    },
]

export const REQUEST_DEPARTMENT_OTPIONS = [
    {
        text: 'Hệ thống',
        value: 1,
    },
    {
        text: 'Kho',
        value: 2,
    },
    {
        text: 'Vận chuyển',
        value: 3,
    },
    {
        text: 'Kế toán',
        value: 4,
    },
]

export const ORDER_REQUEST_DEFAULT = {
    tracking_id: null,
    type: null,
    incharge_department: null,
    content: null,
    cash_on_delivery: '',
    request_new_cod: '',
}

export const SSC_DELIVERY_CONTRACT = -1
export const TVC_DELIVERY_CONTRACT = -2

export const GOODS_ISSUE_STATE_NAME = {
    0: 'Hủy đơn',
    20: 'Thiếu hàng',
    35: 'Chờ mã vận chuyển',
    40: 'Đã tiếp nhận',
    60: 'Đang xử lý',
    100: 'Đã pick',
    160: 'Đã đóng gói',
    200: 'Đã bàn giao vận chuyển',
    220: 'Đã nhận hoàn',
    240: 'Đã nhập hoàn',
}

export const BARCODE_MULTIPACK = 'SSC-MULTIPACK-SSC'
export const BARCODE_LOST_LABEL = 'SSC-RETURNITEMLOSTLABEL-SSC'

export const PACKAGING_TYPES = [
    'carton', 'pe', 'kraft_bubble', 'no_pack', 'bubble', 'non_woven_fabric', 'labels', 'ink', 'tape', 'paper', 'ribbon'
]

export const PACKAGING_UNITS = ['cuộn','bó','hộp','tập','cây']

export const GOODS_UID_ACTION_TYPE_GEN_STAMP = 1
export const GOODS_UID_ACTION_TYPE_RECEIPT = 2
export const GOODS_UID_ACTION_TYPE_RECEIPT_ROLL = 3
export const GOODS_UID_ACTION_TYPE_RECEIPT_HAS_UID = 4
export const GOODS_UID_ACTION_TYPE_RECEIPT_STOWING = 5
export const GOODS_UID_ACTION_TYPE_RETURN = 6
export const GOODS_UID_ACTION_TYPE_RETURN_STOWING = 7
export const GOODS_UID_ACTION_TYPE_PICKUP = 8
export const GOODS_UID_ACTION_TYPE_PACKING = 9
export const GOODS_UID_ACTION_TYPE_RECEIPT_UPDATE_QUANTITY = 10
export const GOODS_UID_ACTION_TYPE_RETURN_UPDATE_QUANTITY = 11
export const GOODS_UID_ACTION_TYPE_UID_ROLL = 12
export const GOODS_UID_ACTION_TYPE_POS_STOWING = 13
export const GOODS_UID_ACTION_TYPE_POS_SELL = 14
export const GOODS_UID_ACTION_TYPE_POS_SELL_ROLL = 15
export const GOODS_UID_ACTION_TYPE_PREPAIR = 16
export const GOODS_UID_ACTION_TYPE_ERROR_EXPORT = 17
export const GOODS_UID_ACTION_TYPE_RECEIPT_OLD_TRANSFER = 18
export const GOODS_UID_ACTION_TYPE_POS_GENERATE_AGAIN_STAMP = 19
export const GOODS_UID_ACTION_TYPE_POS_SET_LOCATION = 20
export const GOODS_UID_ACTION_TYPE_POS_GENERATE_ONE_STAMP = 21
export const GOODS_UID_ACTION_TYPE_RECEIPT_WRONG_RETURN = 22
export const GOODS_UID_ACTION_TYPE_FIND_BASKET = 23
export const GOODS_UID_ACTION_TYPE_RECEIPT_ONE = 24
export const GOODS_UID_ACTION_TYPE_FIND_LOCATION = 25

export const CUSTOM_VIEW_POS_ORDER = 15

export const FIND_GOODS_CUSTOMER_STATUS = [0, 1, 2, 3]

export const ORDER_SOURCE_OPTIONS = [
    {
        text: 'Vãng lai',
        value: 1,
    },
    {
        text: 'Quảng cáo',
        value: 2,
    },
]


export const EXPORT_DEFECTIVE_OPTIONS = [
    {
        text: 'Đã xuất',
        value: 1
    },
    {
        text: 'Chưa xuất',
        value: 0
    },
]