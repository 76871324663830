export function checkUserLoginPermission(userPermissions, permissions) {
    if (!permissions || permissions.length === 0) {
        return true
    }
    if (typeof userPermissions === 'string') {
        userPermissions = [userPermissions]
    }
    if (userPermissions.includes("super_admin")) {
        return true
    }
    const checkPermission = userPermissions.filter(permission => permissions.includes(permission)) || []
    return checkPermission && checkPermission.length > 0
}

export function checkUserLoginModule(userModules, modules) {
    if (!modules || modules.length === 0) {
        return true
    }
    if (typeof userModules === 'string') {
        userModules = [userModules]
    }
    const checkModule = userModules.filter(module => modules.includes(module)) || []
    return checkModule && checkModule.length > 0
}
