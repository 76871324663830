<template>
  <v-autocomplete
    class="c-input-small c-select-multi"
    multiple
    :value="value"
    :items="options"
    :label="label"
    :disabled="disabled"
    :class="customClass"
    :outlined="outlined"
    :dense="dense"
    :hide-details="hideDetails"
    :single-line="singleLine"
    @input="updateValue"
  >
    <template v-slot:prepend-item>
      <v-list-item ripple @mousedown.prevent @click="toggle">
        <v-list-item-action>
          <v-icon :color="value.length > 0 ? 'indigo darken-4' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("labels.all") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </template>
    <template v-slot:selection="{ item, index }">
      <template v-if="likesAllOption">
        <span v-if="index === 0" class="c-select-multi-selected">{{
          $t("labels.all")
        }}</span>
      </template>
      <template v-else>
        <span v-if="index === 0" class="c-select-multi-selected">{{
          item.text
        }}</span>
        <span v-if="index === 1" class="pl-1 grey--text text-caption">
          (+{{ value.length - 1 }})
        </span>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "SelectMultiple",
  props: {
    value: {
      type: [Number, String, Array, Object],
      default: () => null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    outlined: {
      type: Boolean,
      default: () => true,
    },
    dense: {
      type: Boolean,
      default: () => true,
    },
    hideDetails: {
      type: Boolean,
      default: () => true,
    },
    singleLine: {
      type: Boolean,
      default: () => false,
    },
    customClass: {
      type: String,
      default: () => null,
    },
    label: {
      type: String,
      default: () => null,
    },
  },
  model: {
    prop: ["value"],
    event: "change",
  },
  computed: {
    likesAllOption() {
      return this.value.length === this.options.length;
    },
    likesSomeOption() {
      return this.value.length > 0 && !this.likesAllOption;
    },
    icon() {
      if (this.likesAllOption) return "mdi-close-box";
      if (this.likesSomeOption) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllOption) {
          const value = [];
          this.updateValue(value);
        } else {
          const value = this.options.map((o) => o.value);
          this.updateValue(value);
        }
      });
    },
    updateValue(val) {
      this.$emit("change", val);
    },
  },
};
</script>

<style scoped></style>
