import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutMain from '../views/layout/Main.vue'
import auth from '../middleware/auth'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/notification',
        component: LayoutMain,
        meta: {
            permissions: [],
            middleware: [auth],
            pageName: 'SSC',
            title: 'SSC',
        },
        children: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                component: () => import('../views/pages/Dashboard'),
                meta: {
                    permissions: ['pos_dashboard_manager'],
                    middleware: [auth],
                    pageName: '',
                    title: '',
                },
            },

            {
                path: '/notification',
                name: 'Notification',
                component: () => import('../views/pages/Notification.vue'),
                meta: {
                    permissions: [],
                    middleware: [auth],
                    pageName: 'Thông báo',
                    title: 'Thông báo',
                },
            },
            {
                path: '/employee',
                name: 'Employee',
                component: () => import('../views/pages/employee/Index.vue'),
                meta: {
                    permissions: ['pos_employee_manager'],
                    middleware: [auth],
                    pageName: 'QL. Nhân viên',
                    title: 'QL. Nhân viên',
                },
            },
            /**
             * Account
             */
            {
                path: '/change-password',
                name: 'ChangePassword',
                component: () => import('../views/pages/account/ChangePassword.vue'),
                meta: {
                    permissions: [],
                    middleware: [auth],
                    pageName: 'Đổi mật khẩu',
                    title: 'Đổi mật khẩu',
                },
            },
            /**
             * End Account
             */


            /**
             * POS
             */
            {
                path: '/timekeeping',
                name: 'Timekeeping',
                component: () => import('../views/pages/timekeeping/Index.vue'),
                meta: {
                    permissions: [],
                    middleware: [auth],
                    pageName: 'Chấm công',
                    title: 'Chấm công',
                },
            },
            {
                path: '/goods-list',
                name: 'PosGoodsList',
                component: () => import('../views/pages/pos_goods/Index'),
                meta: {
                    permissions: ['pos_goods_manager'],
                    middleware: [auth],
                    pageName: 'QL. Hàng',
                    title: 'QL. Hàng',
                },
            },
            {
                path: '/goods-uid',
                name: 'PosGoodsUid',
                component: () => import('../views/pages/pos_goods/Uid'),
                meta: {
                    permissions: ['pos_goods_uid_manager'],
                    middleware: [auth],
                    pageName: 'QL. UID',
                    title: 'QL. UID',
                },
            },
            {
                path: '/goods-uid-location',
                name: 'PosGoodsUidLocation',
                component: () => import('../views/pages/pos_goods/UidLocation'),
                meta: {
                    permissions: ['pos_goods_uid_location'],
                    middleware: [auth],
                    pageName: 'Vị trí UID',
                    title: 'Vị trí UID',
                },
            },
            {
                path: '/goods-receipt-history',
                name: 'GoodsReceiptHistory',
                component: () => import('../views/pages/pos_goods/ReceiptHistory'),
                meta: {
                    permissions: ['pos_goods_receipt_history'],
                    middleware: [auth],
                    pageName: 'QL. Nhập',
                    title: 'QL. Nhập',
                },
            },
            {
                path: '/goods-receipt-normal',
                name: 'GoodsReceiptNormal',
                component: () => import('../views/pages/pos_goods/ReceiptNormal'),
                meta: {
                    permissions: ['pos_goods_receipt_normal'],
                    middleware: [auth],
                    pageName: 'Nhập: Kiểm - Thường',
                    title: 'Nhập: Kiểm - Thường',
                },
            },
            {
                path: '/goods-receipt/stowing',
                name: 'GoodsReceiptStowing',
                component: () => import('../views/pages/pos_goods/ReceiptStowing.vue'),
                meta: {
                    permissions: ['pos_goods_receipt_stowing'],
                    middleware: [auth],
                    pageName: 'Lên kệ',
                    title: 'Lên kệ',
                    moduleWorkingName: 'receipt_stowing',
                },
            },
            {
                path: '/goods-receipt-has-uid',
                name: 'PosGoodsReceiptHasUid',
                component: () => import('../views/pages/pos_goods/HasStampNew'),
                meta: {
                    permissions: ['pos_goods_receipt_uid'],
                    middleware: [auth],
                    pageName: 'Nhập: Kiểm - SP. Dán UID',
                    title: 'Nhập: Kiểm - SP. Dán UID',
                },
            },
            {
                path: '/goods-receipt-old-transfer',
                name: 'PosGoodsReceiptOldTransfer',
                component: () => import('../views/pages/pos_goods/OldTransfer'),
                meta: {
                    permissions: ['pos_goods_receipt_old_transfer'],
                    middleware: [auth],
                    pageName: 'Nhập: SSC lần đầu',
                    title: 'Nhập: SSC lần đầu',
                },
            },
            {
                path: '/goods-receipt-old-stamp',
                name: 'PosGoodsReceiptOldStamp',
                component: () => import('../views/pages/pos_goods/OldPrintStamp'),
                meta: {
                    permissions: ['pos_goods_receipt_old_stamp'],
                    middleware: [auth],
                    pageName: 'In tem lẻ',
                    title: 'In tem lẻ',
                },
            },
            {
                path: '/generate-stamp-again',
                name: 'PosGenerateStampAgain',
                component: () => import('../views/pages/pos_goods/GenerateStampAgain'),
                meta: {
                    permissions: ['pos_goods_generate_stamp_again'],
                    middleware: [auth],
                    pageName: 'In lại tem',
                    title: 'In lại tem',
                },
            },
            {
                path: '/goods-receipt-direct-multiple',
                name: 'PosGoodsReceiptDirectMultiple',
                component: () => import('../views/pages/pos_goods/ReceiptDirectMultiple'),
                meta: {
                    permissions: ['pos_goods_receipt'],
                    middleware: [auth],
                    pageName: 'Nhập nhiều',
                    title: 'Nhập nhiều',
                },
            },
            {
                path: '/goods-receipt-direct-one',
                name: 'PosGoodsReceiptDirectOne',
                component: () => import('../views/pages/pos_goods/ReceiptDirectOne'),
                meta: {
                    permissions: ['pos_goods_receipt'],
                    middleware: [auth],
                    pageName: 'Nhập lẻ',
                    title: 'Nhập lẻ',
                },
            },
            {
                path: '/goods-receipt-create',
                name: 'PosGoodsReceiptCreate',
                component: () => import('../views/pages/pos_goods/ReceiptCreate'),
                meta: {
                    permissions: ['pos_goods_receipt_create'],
                    middleware: [auth],
                    pageName: 'Ký gửi hàng hóa',
                    title: 'Ký gửi hàng hóa',
                },
            },
            {
                path: '/goods-set-position',
                name: 'PosGoodsSetPosition',
                component: () => import('../views/pages/pos_goods/SetPosition'),
                meta: {
                    permissions: ['pos_goods_set_position'],
                    middleware: [auth],
                    pageName: 'Gán Sọt → Vị trí',
                    title: 'Gán Sọt → Vị trí',
                },
            },
            {
                path: '/goods-set-position-free',
                name: 'PosGoodsSetPositionFree',
                component: () => import('../views/pages/pos_goods/SetPositionFree'),
                meta: {
                    permissions: ['pos_goods_set_position_free'],
                    middleware: [auth],
                    pageName: 'Gán SP → Vị trí',
                    title: 'Gán SP → Vị trí',
                },
            },
            {
                path: '/goods-display-stowing',
                name: 'PosGoodsStowingDisplay',
                component: () => import('../views/pages/pos_goods/StowingDisplay'),
                meta: {
                    permissions: ['pos_goods_display_stowing'],
                    middleware: [auth],
                    pageName: 'Trưng bày',
                    title: 'Trưng bày',
                },
            },
            {
                path: '/goods-pickup-normal',
                name: 'PosGoodsPickupNormal',
                component: () => import('../views/pages/pos_goods/Pickup.vue'),
                meta: {
                    permissions: ['pos_goods_pickup'],
                    middleware: [auth],
                    pageName: 'Pickup tùy chọn ',
                    title: 'Pickup tùy chọn ',
                },
            },
            {
                path: '/goods-pickup-display',
                name: 'PosGoodsPickupDisplay',
                component: () => import('../views/pages/pos_goods/PickupDisplay.vue'),
                meta: {
                    permissions: ['pos_goods_pickup'],
                    middleware: [auth],
                    pageName: 'Pickup trưng bày',
                    title: 'Pickup trưng bày',
                },
            },
            {
                path: '/goods-set-action',
                name: 'PosGoodsSetAction',
                component: () => import('../views/pages/pos_goods/SetAction.vue'),
                meta: {
                    permissions: ['pos_goods_set_action'],
                    middleware: [auth],
                    pageName: 'Chuẩn bị hàng',
                    title: 'Chuẩn bị hàng',
                },
            },
            {
                path: '/goods-uid-failed',
                name: 'PosGoodsUidFailed',
                component: () => import('../views/pages/pos_goods/UidFailed.vue'),
                meta: {
                    permissions: ['pos_goods_uid_failed'],
                    middleware: [auth],
                    pageName: "QC - Hàng lỗi",
                    title: "QC - Hàng lỗi",
                },
            },
            {
                path: '/goods-export-defective',
                name: 'PosGoodsExportDefective',
                component: () => import('../views/pages/pos_goods/ExportDefective.vue'),
                meta: {
                    permissions: ['pos_goods_export_defective'],
                    middleware: [auth],
                    pageName: 'Xuất hàng lỗi',
                    title: 'Xuất hàng lỗi',
                },
            },
            {
                path: '/goods-borrow-return',
                name: 'PosGoodsBorrowReturnr',
                component: () => import('../views/pages/pos_goods/BorrowReturn.vue'),
                meta: {
                    permissions: ['pos_goods_borrow_return'],
                    middleware: [auth],
                    pageName: 'Mượn | Trả sản phẩm',
                    title: 'Mượn | Trả sản phẩm',
                },
            },
            {
                path: '/goods-print-stamp',
                name: 'GoodsPrintStamp',
                component: () => import('../views/pages/pos_goods/PrintStamp.vue'),
                meta: {
                    permissions: ['pos_download_goods_stamp'],
                    middleware: [auth],
                    pageName: 'In tem',
                    title: 'In tem',
                },
            },
            {
                path: '/goods/find',
                name: 'GoodsFind',
                component: () => import('../views/pages/pos_goods/Find.vue'),
                meta: {
                    permissions: [],
                    middleware: [auth],
                    pageName: 'Tìm hàng',
                    title: 'Tìm hàng',
                },
            },
            {
                path: '/goods/find-manager',
                name: 'GoodsFindManager',
                component: () => import('../views/pages/pos_goods/FindManager.vue'),
                meta: {
                    permissions: ['find_goods_manager', 'warehouse_admin'],
                    middleware: [auth],
                    pageName: 'Yêu cầu tìm hàng',
                    title: 'Yêu cầu tìm hàng',
                },
            },
            {
                path: '/order-create',
                name: 'PosOrderCreate',
                component: () => import('../views/pages/pos/OrderCreate.vue'),
                meta: {
                    permissions: ['pos_order_create'],
                    middleware: [auth],
                    pageName: 'Bán hàng',
                    title: 'Bán hàng',
                },
            },
            {
                path: '/order-list',
                name: 'PosOrderList',
                component: () => import('../views/pages/pos/OrderList.vue'),
                meta: {
                    permissions: ['pos_order_list'],
                    middleware: [auth],
                    pageName: 'Thống kê bán hàng',
                    title: 'Thống kê bán hàng',
                },
            },
            {
                path: '/order-online-list',
                name: 'PosOrderOnlineList',
                component: () => import('../views/pages/order_online/Index.vue'),
                meta: {
                    permissions: ['pos_order_online_list'],
                    middleware: [auth],
                    pageName: 'Đơn online',
                    title: 'Đơn online',
                },
            },
            {
                path: '/order-online-detail',
                name: 'PosOrderOnlineDetail',
                component: () => import('../views/pages/order_online/Detail.vue'),
                meta: {
                    permissions: ['pos_order_online_detail'],
                    middleware: [auth],
                    pageName: 'Chi tiết đơn online',
                    title: 'Chi tiết đơn online',
                },
            },
            {
                path: '/order-online-pickup',
                name: 'PosOrderOnlinePickup',
                component: () => import('../views/pages/order_online/Pickup.vue'),
                meta: {
                    permissions: ['pos_order_online_pickup'],
                    middleware: [auth],
                    pageName: 'Đơn online - Pickup',
                    title: 'Đơn online - Pickup',
                },
            },
            {
                path: '/order-online-packing',
                name: 'PosOrderOnlinePacking',
                component: () => import('../views/pages/order_online/Packing.vue'),
                meta: {
                    permissions: ['pos_order_online_packing'],
                    middleware: [auth],
                    pageName: 'Đơn online - Đóng gói',
                    title: 'Đơn online - Đóng gói',
                },
            },
            {
                path: '/order-online-handover',
                name: 'PosOrderOnlineHandover',
                component: () => import('../views/pages/order_online/Handover.vue'),
                meta: {
                    permissions: ['pos_order_online_handover'],
                    middleware: [auth],
                    pageName: 'Đơn online - Bàn giao',
                    title: 'Đơn online - Bàn giao',
                },
            },
            {
                path: '/order-online-return-receive',
                name: 'PosOrderOnlineReturnReceive',
                component: () => import('../views/pages/order_online/ReturnReceive.vue'),
                meta: {
                    permissions: ['pos_order_online_return_receive'],
                    middleware: [auth],
                    pageName: 'Đơn online - Nhận hoàn',
                    title: 'Đơn online - Nhận hoàn',
                },
            },
            {
                path: '/order-online-return-import',
                name: 'PosOrderOnlineReturnImport',
                component: () => import('../views/pages/order_online/ReturnImport.vue'),
                meta: {
                    permissions: ['pos_order_online_return_import'],
                    middleware: [auth],
                    pageName: 'Đơn online - Nhập hoàn',
                    title: 'Đơn online - Nhập hoàn',
                },
            },
            {
                path: '/order-online-return-stowing',
                name: 'PosOrderOnlineReturnStowing',
                component: () => import('../views/pages/order_online/ReturnStowing.vue'),
                meta: {
                    permissions: ['pos_order_online_return_stowing'],
                    middleware: [auth],
                    pageName: 'Đơn online - Lên kệ hàng hoàn',
                    title: 'Đơn online - Lên kệ hàng hoàn',
                },
            },
            {
                path: '/order-online-return-list',
                name: 'PosOrderOnlineReturnHistory',
                component: () => import('../views/pages/order_online/ReturnHistory.vue'),
                meta: {
                    permissions: ['pos_order_online_return_history'],
                    middleware: [auth],
                    pageName: 'QL. Hoàn',
                    title: 'QL. Hoàn',
                },
            },
            {
                path: '/internal-request',
                name: 'InternalRequest',
                component: () => import('../views/pages/internal_requests/Index.vue'),
                meta: {
                    permissions: ['pos_internal_request'],
                    middleware: [auth],
                    pageName: 'Yêu cầu xử lý',
                    title: 'Yêu cầu xử lý',
                },
            },
            {
                path: '/administration',
                name: 'Administration',
                component: () => import('../views/pages/administration/Index.vue'),
                meta: {
                    permissions: ['pos_admin_package', 'pos_admin_bill', 'pos_admin_payroll', 'pos_admin_timekeeping'],
                    middleware: [auth],
                    pageName: 'Quản trị',
                    title: 'Quản trị',
                },
            },
            {
                path: '/packaging',
                name: 'Packaging',
                component: () => import('../views/pages/packaging/Index.vue'),
                meta: {
                    permissions: ['pos_packaging_manager'],
                    middleware: [auth],
                    pageName: 'QL. Vật tư',
                    title: 'QL. Vật tư',
                },
            },
            {
                path: '/test',
                name: 'Test',
                component: () => import('../views/pages/test/Index.vue'),
                meta: {
                    permissions: [],
                    middleware: [auth],
                    pageName: 'Test',
                    title: 'Test',
                },
            },
            /**
             * End POS
             */
        ]
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/pages/Register.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/pages/Login.vue'),
    },
    {
        path: '/403',
        name: 'Error403',
        component: () => import('../views/pages/errors/403.vue'),
    }
]


const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
})

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        context.next(...parameters);
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);
        return middleware[0]({ ...context, next: nextMiddleware });
    }

    return next();
});

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || process.env.VUE_APP_TITLE;
    });
});

export default router
