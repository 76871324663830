<template>
  <v-autocomplete
    class="c-input-small"
    :value="value"
    :items="filteredOptions"
    :label="label"
    :disabled="disabled"
    :class="customClass"
    :outlined="outlined"
    :dense="dense"
    :hide-details="hideDetails"
    :single-line="singleLine"
    @input="updateValue"
  ></v-autocomplete>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "SelectEmployeeModel",
  props: {
    value: {
      type: [Number, String, Array, Object],
      default: () => null,
    },
    label: {
      type: String,
      default: () => "Nhân viên",
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    outlined: {
      type: Boolean,
      default: () => true,
    },
    dense: {
      type: Boolean,
      default: () => true,
    },
    hideDetails: {
      type: Boolean,
      default: () => true,
    },
    singleLine: {
      type: Boolean,
      default: () => true,
    },
    customClass: {
      type: String,
      default: () => null,
    },
    idPos: {
      type: [String, Number],
      default: () => null,
    },
  },
  data: () => ({
    options: [],
  }),
  model: {
    prop: ["value"],
    event: "change",
  },
  computed: {
    filteredOptions() {
      return [...this.options].filter(
        (otp) => ![39, 152, 208, 488, 805].includes(otp.id_identity)
      );
    },
  },
  watch: {
    idPos() {
      this.getEmployees();

      const checkEmployee = this.filteredOptions.find(
        (opt) => opt.value === this.value
      );
      if (!checkEmployee) {
        this.updateValue(null);
      }
    },
  },
  mounted() {
    this.getEmployees();
  },
  methods: {
    updateValue(val) {
      this.$emit("change", val);
    },
    async getEmployees() {
      const { data } = await httpClient.post("/employee-active-my-pos", {
        id_pos: this.idPos,
      });
      this.options = [...data].map((item) => ({
        value: item.id,
        text: item.full_name || "",
        id_identity: item.id_identity,
      }));
    },
  },
};
</script>

<style scoped></style>
