<template>
  <div>
    <router-view></router-view>
    <div v-if="spinnerLoading" class="loading">loading...</div>
  </div>
</template>

<script>
import { eventHub } from "@/libs/eventhub";
import { debounce } from "lodash/function";

export default {
  name: "App",
  data: () => ({
    spinnerLoading: false,
  }),
  methods: {
    onLoading() {
      this.spinnerLoading = true;
    },
    endLoading: debounce(function () {
      this.spinnerLoading = false;
    }, 500),
  },
  created() {
    eventHub.$on("isLoading", this.onLoading);
    eventHub.$on("endLoading", this.endLoading);
  },
  beforeDestroy() {
    eventHub.$off("isLoading", this.onLoading);
    eventHub.$off("endLoading", this.endLoading);
  },
};
</script>

<style lang="scss">
@-webkit-keyframes loadingSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes loadingSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes loadingSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loadingSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  &:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(20, 20, 20, 0.05), rgba(0, 0, 0, 0.05));
    background: -webkit-radial-gradient(
      rgba(20, 20, 20, 0.05),
      rgba(0, 0, 0, 0.05)
    );
  }
  &:not(:required) {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
    &:after {
      content: "";
      display: block;
      font-size: 10px;
      width: 1em;
      height: 1em;
      margin-top: -0.5em;
      -webkit-animation: loadingSpinner 150ms infinite linear;
      -moz-animation: loadingSpinner 150ms infinite linear;
      -ms-animation: loadingSpinner 150ms infinite linear;
      -o-animation: loadingSpinner 150ms infinite linear;
      animation: loadingSpinner 150ms infinite linear;
      border-radius: 0.5em;
      -webkit-box-shadow: rgba(50, 50, 50, 0.75) 1.5em 0 0 0,
        rgba(50, 50, 50, 0.75) 1.1em 1.1em 0 0,
        rgba(50, 50, 50, 0.75) 0 1.5em 0 0,
        rgba(50, 50, 50, 0.75) -1.1em 1.1em 0 0,
        rgba(50, 50, 50, 0.75) -1.5em 0 0 0,
        rgba(50, 50, 50, 0.75) -1.1em -1.1em 0 0,
        rgba(50, 50, 50, 0.75) 0 -1.5em 0 0,
        rgba(50, 50, 50, 0.75) 1.1em -1.1em 0 0;
      box-shadow: rgba(50, 50, 50, 0.75) 1.5em 0 0 0,
        rgba(50, 50, 50, 0.75) 1.1em 1.1em 0 0,
        rgba(50, 50, 50, 0.75) 0 1.5em 0 0,
        rgba(50, 50, 50, 0.75) -1.1em 1.1em 0 0,
        rgba(50, 50, 50, 0.75) -1.5em 0 0 0,
        rgba(50, 50, 50, 0.75) -1.1em -1.1em 0 0,
        rgba(50, 50, 50, 0.75) 0 -1.5em 0 0,
        rgba(50, 50, 50, 0.75) 1.1em -1.1em 0 0;
    }
  }
}
</style>
