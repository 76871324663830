<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :disabled="disabled"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :disabled="disabled"
        :label="label"
        :placeholder="placeholder"
        :value="selectedDate"
        :class="customClass"
        :outlined="outlined"
        :dense="dense"
        :hide-details="hideDetails"
        :single-line="singleLine"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      type="month"
      no-title
      scrollable
      locale="vi-vn"
      :min="minDate"
      :disabled="disabled"
      @input="okHandler"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";

export default {
  name: "MonthPickerModel",
  model: {
    prop: ["value"],
    event: "change",
  },
  props: {
    value: {
      type: [Date, Number, String, Array, Object],
      default: () => null,
    },
    label: {
      type: String,
      default: () => null,
    },
    placeholder: {
      type: String,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    outlined: {
      type: Boolean,
      default: () => true,
    },
    dense: {
      type: Boolean,
      default: () => true,
    },
    hideDetails: {
      type: Boolean,
      default: () => true,
    },
    singleLine: {
      type: Boolean,
      default: () => true,
    },
    customClass: {
      type: String,
      default: () => null,
    },
    minDate: {
      type: String,
      default: () => "",
    },
  },
  data: () => ({
    date: "",
    menu: false,
  }),

  computed: {
    selectedDate() {
      if (!this.date) {
        return null;
      }
      return moment(this.date).format("MM/YYYY");
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.value) {
        return false;
      }
      this.date = moment(this.value).format("YYYY-MM-DD");
    },
    okHandler() {
      this.resetPicker();
      this.$emit("change", this.date);
    },
    clearHandler() {
      this.resetPicker();
      this.date = null;
      this.$emit("change", this.date);
    },
    resetPicker() {
      this.menu = false;
    },
  },
};
</script>

<style scoped></style>
